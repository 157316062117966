<template>
  <el-card style="width: 100%;margin-bottom: 30px;">
    <div class="card-header">
      <el-form :inline="true" :model="searchForm" class="demo-form-inline">
        <el-form-item>
          <!-- <el-button type="primary" @click="onSubmit">查询</el-button> -->
          <el-button type="primary" @click="dialogVisible = true; isadd = true;">新增品牌</el-button>
        </el-form-item>
      </el-form>
    </div>
  </el-card>
  <el-table :data="tableData" style="width: 100%" height="700">
    <el-table-column prop="logo" label="图片" align="center">
      <template v-slot="{ row }">
        <img :src="row.logo" alt="品牌图片" srcset="" class="avator" />
      </template>
    </el-table-column>
    <el-table-column prop="link" label="跳转链接" align="center" />
    <el-table-column prop="action" label="操作" fixed="right" width="250">
      <template #default="scope">
        <el-button type="primary" size="small" @click="deleteBrand(scope.row)">删除</el-button>
      </template>
    </el-table-column>
  </el-table>
  <el-pagination layout="prev, pager, next" :total="total" @current-change="changepage" :current-page="currentPage" />
  <el-dialog v-model="dialogVisible" title="新增品牌" @close="reset(formLabelAlignRef)">
    <!-- <img  :src="formLabelAlign.logo" /> -->
    <el-form label-position="left" label-width="auto" :model="formLabelAlign" :rules="Dramarules"
      ref="formLabelAlignRef">

      <!-- <el-form-item label="电影简介" prop="intro">
        <div>
          <Toolbar style="border-bottom: 1px solid #ccc" :editor="editorRef" :defaultConfig="toolbarConfig"
            mode="default" />
          <Editor style="height: 500px; overflow-y: hidden;" v-model="formLabelAlign.intro"
            :defaultConfig="editorConfig" mode="default" @onCreated="handleCreated" />
        </div>

      </el-form-item> -->
      <el-form-item label="品牌图标" prop="logo">
        <el-upload class="avatar-uploader" :show-file-list="false" :http-request="beforeAvatarUpload" name="image">
          <!-- <img v-if="formLabelAlign.logo.length!==0" :src="formLabelAlign.logo" class="avatar" /> -->
          <img :src="formLabelAlign.logo" v-if="formLabelAlign.logo" class="avatar" />
          <el-icon v-else class="avatar-uploader-icon">
            <Plus />
          </el-icon>
        </el-upload>
      </el-form-item>
      <el-form-item label="跳转链接" required prop="link">
        <el-input v-model="formLabelAlign.link" />
      </el-form-item>

    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="reset(formLabelAlignRef)">取消</el-button>
        <el-button type="primary" @click="creatFilm(formLabelAlignRef)">
          确认
        </el-button>
      </div>
    </template>
  </el-dialog>

  <el-dialog v-model="planVisible" title="演出计划" width="1200">
    <el-button type="primary" size="small" @click="addplanVisible = true;">新增演出计划</el-button>
    <el-table :data="plantableData" style="width: 100%" height="850">
      <el-table-column prop="id" label="ID" />
      <el-table-column prop="Hid" label="剧场" width="250">
        <template v-slot="{ row }">
          <p>{{ row.Hid == 1 ? '大放映厅' : '小放映厅' }}</p>
        </template>
      </el-table-column>
      <el-table-column prop="PlanType" label="演出类型" width="180" />
      <el-table-column prop="FTime" label="放映时间" width="180" />
      <el-table-column prop="StartTime" label="开始时间" width="180" />
      <el-table-column prop="EndTime" label="结束时间" width="180" />
      <el-table-column prop="Event" label="演出场次" width="180" />
      <el-table-column prop="Sponsor" label="主办方" width="180" />
      <el-table-column prop="action" label="操作" fixed="right" width="250">
        <template v-slot="{ row }">
          <el-button type="primary" size="small" @click="editplan(row)">编辑</el-button>
          <el-button type="primary" size="small" @click="addprice(row, true)">发布票价</el-button>
          <el-button type="primary" size="small" @click="addprice(row, false)">编辑票价</el-button>
        </template>
      </el-table-column>
    </el-table>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="planVisible = false">取消</el-button>
        <el-button type="primary" @click="planVisible = false">
          确认
        </el-button>
      </div>
    </template>
  </el-dialog>

  <el-dialog v-model="addplanVisible" title="演出计划" width="800" @close="resetaddPlan(planRef)">
    <el-form :inline="true" :model="formInline" class="demo-form-inline" ref="planRef" :rules="planRules">

      <el-form-item label="剧场" prop="Hid">
        <el-select v-model="formInline.Hid" placeholder="请选择" clearable>
          <el-option label="大放映厅" value="1" />
          <el-option label="小放映厅" value="2" />
        </el-select>
      </el-form-item>
      <el-form-item label="演出类型" prop="PlanType">
        <el-select v-model="formInline.PlanType" placeholder="请选择" clearable>
          <el-option label="商务演出" value="商务演出" />
          <el-option label="优惠场次" value="优惠场次" />
          <el-option label="惠民演出" value="惠民演出" />
        </el-select>
      </el-form-item>
      <el-form-item label="放映日期" prop="FTime">
        <el-date-picker v-model="formInline.FTime" format="YYYY/MM/DD HH:mm:ss" value-format="YYYY-MM-DD HH:mm:ss"
          type="datetime" placeholder="请选择日期" />
      </el-form-item>
      <el-form-item label="开始时间" prop="StartTime">
        <el-date-picker v-model="formInline.StartTime" format="YYYY/MM/DD HH:mm:ss" value-format="YYYY-MM-DD HH:mm:ss"
          type="datetime" placeholder="请选择日期" />
      </el-form-item>
      <el-form-item label="结束时间" prop="EndTime">
        <el-date-picker v-model="formInline.EndTime" format="YYYY/MM/DD HH:mm:ss" value-format="YYYY-MM-DD HH:mm:ss"
          type="datetime" placeholder="请选择日期" />
      </el-form-item>
      <el-form-item label="演出场次" prop="Event">
        <el-input v-model="formInline.Event" placeholder="请输入演出场次" clearable />
      </el-form-item>
      <el-form-item label="主办方" prop="Sponsor">
        <el-input v-model="formInline.Sponsor" placeholder="请填写主办方" clearable />
      </el-form-item>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="resetaddPlan(planRef)">取消</el-button>
        <el-button type="primary" @click="creatplan(planRef)">
          确认
        </el-button>
      </div>
    </template>
  </el-dialog>
  <el-dialog v-model="priceVisible" title="票价划分" width="800" @close="resetprice(priceRef)">
    <el-form :inline="true" :model="priceForm" class="demo-form-inline" ref="priceRef" :rules="priceRules">
      <el-form-item label="一等座" required prop="price_1">
        <el-input-number v-model="priceForm.price_1" placeholder="请填写价格" clearable :precision="0" />
      </el-form-item>
      <el-form-item label="二等座" required prop="price_2">
        <el-input-number v-model="priceForm.price_2" placeholder="请填写价格" clearable :precision="0" />
      </el-form-item>
      <el-form-item label="三等座" required prop="price_3">
        <el-input-number v-model="priceForm.price_3" placeholder="请填写价格" clearable :precision="0" />
      </el-form-item>
      <el-form-item label="四等座" required prop="price_4">
        <el-input-number v-model="priceForm.price_4" placeholder="请填写价格" clearable :precision="0" />
      </el-form-item>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="resetprice(priceRef)">取消</el-button>
        <el-button type="primary" @click="creatprice(priceRef)">
          确认
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script setup>
import '@wangeditor/editor/dist/css/style.css' // 引入 css
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import { ElMessage } from "element-plus";
import { Plus } from '@element-plus/icons-vue'
import { getCurrentInstance, reactive, ref, onBeforeUnmount, shallowRef, onMounted } from 'vue'
import { getArticleList, createArticle, uploadImg, getplanList, upPlan, ticketPrice, ticketPricechange, priceInfo, FilmInfo } from '@/api/article.js'
const formLabelAlignRef = ref()
const planRef = ref()
const priceRef = ref()
let isadd = ref(true)
const dialogVisible = ref(false)
const planVisible = ref(false)
const addplanVisible = ref(false)
const priceVisible = ref(false)
const editorRef = shallowRef()
const toolbarConfig = {}
const editorConfig = { placeholder: '请输入内容...', MENU_CONF: {} }
editorConfig.MENU_CONF['uploadImage'] = {
  server: 'http://fhpw.unions88.com/admin/upload/index',
  timeout: 5 * 1000,
  fieldName: 'image',
  customInsert(res, insertFn) {
    console.log(res)
    if (res.status == 200) {
      insertFn(res.data.path, null, res.data.path);
    }
  },
}
const handleCreated = (editor) => {
  editorRef.value = editor // 记录 editor 实例，重要！
}
//剧目ID
let FilmID = ref(0)
//剧目表单
let formLabelAlign = ref({

  logo: '',
  link: '',

})
//剧目查询
const onSubmit = () => {
  console.log('我是查询')
}
let searchForm = reactive({})
//剧目表单规则
const Dramarules = reactive({
  link: [
    { required: true, message: '请输入跳转链接', trigger: 'blur' },
  ],
})
//计划表单
let formInline = ref({
  Fid: 0,
  Hid: "1",
  FTime: "",
  StartTime: '',
  EndTime: '',
  Event: '',
  Sponsor: '',
  PlanType: ''
})
//计划表单规则
const planRules = reactive({
  Hid: [
    { required: true, message: '请选择剧场类型', trigger: 'blur' },
  ],
  PlanType: [
    { required: true, message: '请选择演出类型', trigger: 'blur' },
  ],
  FTime: [
    { required: true, message: '请选择放映日期', trigger: 'blur' },
  ],
  StartTime: [
    { required: true, message: '请选择开始时间', trigger: 'blur' },
  ],
  EndTime: [
    { required: true, message: '请选择结束时间', trigger: 'blur' },
  ],
  Event: [
    { required: true, message: '请输入演出场次', trigger: 'blur' },
  ],
  Sponsor: [
    { required: true, message: '请输入主办方', trigger: 'blur' },
  ],
})
const options = reactive([
  {
    value: '0',
    label: '成人',
  },
  {
    value: '1',
    label: '儿童',
  },
  {
    value: '2',
    label: '历史',
  },
])
//票价表单规则
const priceRules = reactive({
  price_1: [
    { required: true, message: '请输入金额', trigger: 'blur' },
  ],
  price_2: [
    { required: true, message: '请输入金额', trigger: 'blur' },
  ],
  price_3: [
    { required: true, message: '请输入金额', trigger: 'blur' },
  ],
  price_4: [
    { required: true, message: '请输入金额', trigger: 'blur' },
  ],
})

let tableData = ref([])
let plantableData = ref([])
let total = ref(0)
let currentPage = ref(1);
//获取列表参数
const getData = () => {
  getArticleList()
    .then(res => {

      let data = JSON.parse(res.data);
      console.log(data);
      tableData.value = data;
      // total.value = res.data.total;
      // tableData.value = res.data.data;
      // console.log(tableData);
    })
}
getData();
//翻页函数
const changepage = (data) => {
  console.log(data);
  currentPage.value = data;
  getData();
}
//新增剧目or编辑剧目
const creatFilm = async (formEl) => {
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (valid) {
      let list = tableData.value.concat();
      console.log(formLabelAlign.value)
      list.push(formLabelAlign.value)
      createArticle({ data: JSON.stringify(list) })
        .then(res => {
          console.log(res)
          if (res.type == 'kjlm') {
            ElMessage.success('新增成功')
            reset(formEl)
            // dialogVisible.value = false;
            getData();
          }
        })
    } else {
      console.log('error submit!', fields)
    }
  })

}
const reset = (formEl) => {
  formEl.resetFields()
  formLabelAlign.value.logo = '';
  formLabelAlign.value.link = '';
  dialogVisible.value = false;
}

//删除品牌图片
const deleteBrand = (row) => {
  //删除索引为index的数据
  let data = tableData.value.concat();
  let index = tableData.value.findIndex(item => item.logo == row.logo)
  console.log(index)
  let newdata = data.toSpliced(index, 1);
  createArticle({ data: JSON.stringify(newdata) })
    .then(res => {
      console.log(res)
      if (res.type == 'kjlm') {
        ElMessage.success('删除成功')
        getData();
      }
    })
}
//图片上传
const beforeAvatarUpload = (option) => {
  console.log(option);
  let data = new FormData();
  data.append('image', option.file);
  uploadImg(data)
    .then(res => {
      ElMessage.success('上传成功');
      formLabelAlign.value.logo = res.url;
      console.log(res)
    })
}

//编辑剧目
const edit = (row) => {
  isadd.value = false
  console.log(row);
  FilmInfo(row.ID)
    .then(res => {
      formLabelAlign.value.id = res.data.ID;
      formLabelAlign.value.name = res.data.name;
      formLabelAlign.value.director = res.data.director
      formLabelAlign.value.play = res.data.play
      editorRef.value.setHtml(res.data.intro)
      formLabelAlign.value.intro = res.data.intro
      formLabelAlign.value.lang = res.data.lang
      formLabelAlign.value.long = parseInt(res.data.long)
      formLabelAlign.value.logo = `${res.data.FPhoto}?time=${Math.random() * 100}`
      formLabelAlign.value.sortId = res.data.sortId
      formLabelAlign.value.show_time = res.data.show_time
    })
  console.log(formLabelAlign)
  dialogVisible.value = true;
}
//获取剧目演出计划
const getPlan = (row) => {
  FilmID.value = row.ID
  getplanList({
    fid: row.ID
  })
    .then(res => {

      planVisible.value = true;
      console.log(res);
      plantableData.value = res.data;
      addplanVisible.value = false;
    })
}

//新增演出计划
const creatplan = async (formEl) => {
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (valid) {
      formInline.value.Fid = FilmID.value;
      upPlan(formInline.value)
        .then(res => {
          console.log(res);
          ElMessage.success('操作成功')
          resetaddPlan(formEl);
          getplanList({
            fid: FilmID.value
          })
            .then(res => {
              plantableData.value = res.data;

            })
        })
    } else {
      console.log('error submit!', fields)
    }
  })

}

const editplan = (row) => {
  formLabelAlign.value.logo = row.logo;
  formLabelAlign.value.link = row.link;
  dialogVisible.value = true;
}

const resetaddPlan = (formEl) => {
  formEl.resetFields();
  console.log(formInline)
  formInline.value = {
    Fid: 0,
    Hid: "1",
    FTime: "",
    StartTime: '',
    EndTime: '',
    Event: '',
    Sponsor: '',
    PlanType: ''
  }
  addplanVisible.value = false;
}
//计划ID
// let planId = ref(0)
let planIsadd = ref(true)
//票价表单
let priceForm = reactive({
  fpid: '',
  price_1: 0,
  price_2: 0,
  price_3: 0,
  price_4: 0
})
const addprice = (row, type) => {
  priceForm.fpid = row.id;

  if (type) {
    planIsadd.value = true
  } else {
    priceInfo({ fpid: row.id })
      .then(res => {
        priceForm.price_1 = parseInt(res.data.price_1);
        priceForm.price_2 = parseInt(res.data.price_2)
        priceForm.price_3 = parseInt(res.data.price_3)
        priceForm.price_4 = parseInt(res.data.price_4)
      })
    planIsadd.value = false
  }
  priceVisible.value = true;
}

const creatprice = (formEl) => {
  if (planIsadd.value) {
    ticketPrice(priceForm)
      .then(res => {
        ElMessage.success('创建成功');
        priceVisible.value = false;
        formEl.resetFields()

      })
  } else {
    ticketPricechange(priceForm)
      .then(res => {
        ElMessage.success('修改成功');
        priceVisible.value = false;
      })
  }
}
const resetprice = (formEl) => {
  formEl.resetFields();
  priceVisible.value = false;
}
</script>

<style lang="scss" scoped>
.demo-form-inline .el-input {
  --el-input-width: 220px;
}

.demo-form-inline .el-select {
  --el-select-width: 220px;
}

.avatar {
  width: 400px;
  height: 400px;
}

.threehidden {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all
}

.avator {
  width: 140px;
}
</style>
